import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify'
import { useRecoilState } from "recoil";

//import services
import InteractService from "../../../services/interact.service"

// data stores
import { interactState } from "../../../data/interact.atom";

export default function Interacts() {
  const [interacts, setInteracts] = useRecoilState(interactState)


    return (
        <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {interacts.map((interact) => (
            <Link to={"edit/" + interact.uuid + "/"}>
              <li key={interact.uuid} className="relative">
                <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                  <img src={process.env.REACT_APP_BACKEND_URL.slice(0, -1) + interact.image} alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                  <button type="button" className="absolute inset-0 focus:outline-none">
                    <span className="sr-only">View details for {interact.title}</span>
                  </button>
                </div>
                <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{interact.title}</p>
                <p className="block text-sm font-medium text-gray-500 pointer-events-none">Aangemaakt: {interact.created_on}</p>
              </li>
            </Link>
          ))}
        </ul>
    )
  }