import { useState, useEffect, useRef } from 'react'
import useFileUpload from 'react-use-file-upload';
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { toast } from 'react-toastify'
import Compressor from 'compressorjs';

// default components
import Spacer from '../default/spacer'

//services
import UploadService from '../../../services/upload.service'

//datastores
import { updateState } from '../../../data/update.atom'

export default function CreateMedia() {
    const {
        files,
        fileNames,
        fileTypes,
        totalSize,
        totalSizeInBytes,
        handleDragDropEvent,
        clearAllFiles,
        createFormData,
        setFiles,
        removeFile,
    } = useFileUpload();

    const inputRef = useRef();
    const [update, setUpdate] = useRecoilState(updateState)

    const [compression] = useState([0, NaN, 0.8, 0.6, 0.4]);
    const [compressionLang] = useState(["original", "base", "low", "medium", "high"]);

    function renameFile(originalFile, newName) {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }

    function compressFile(imageID, compressionID) {
        const file = files.map((file, index) => { if (index === imageID) return file; });
        const compress = compression[compressionID];

        if (file && compress != 0) {
            console.log(file)
            new Compressor(file[0], {
                quality: compress,
                success(result) {
                    result = renameFile(result, "compressed_" + compressionLang[compressionID] + "_" + file[0].name);
                    const e = {
                        currentTarget: {
                            files: [result]
                        }
                    }
                    setFiles(e, 'a');
                }
            })
        } else {
            toast.error("Compressie is mislukt, probeer het opnieuw.")
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const formData = createFormData();
        UploadService.multipleImage(formData)
            .then((response) => {
                setUpdate(update + 1)
                clearAllFiles();
            })
            .catch((error) => {
                toast.error(error.message)
            })
    }

    useEffect(() => {
        console.log(files);
    }, [files]);

    return (
        <>
            <div className="md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Mediabibliotheek</h2>
                </div>
            </div>
            <Spacer height={20} />


            <div className="m-5">
                <div className="mt-1 sm:mt-0 w-full"
                    onDragEnter={handleDragDropEvent}
                    onDragOver={handleDragDropEvent}
                    onDrop={(e) => {
                        handleDragDropEvent(e);
                        setFiles(e, 'a');
                    }}>
                    <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                        <div className="space-y-1 text-center">
                            <>
                                <svg
                                    className="mx-auto h-12 w-12 text-gray-400"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 48 48"
                                    aria-hidden="true"
                                >
                                    <path
                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                <div className="flex text-sm text-gray-600">
                                    <p>Sleep je bestanden of</p>
                                    <label
                                        htmlFor="file-upload"
                                        className="ml-1 relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                    >
                                        <button onClick={() => inputRef.current.click()}><span>selecteer vanaf je apparaat.</span></button>
                                        <input
                                            ref={inputRef}
                                            type="file"
                                            multiple
                                            style={{ display: 'none' }}
                                            onChange={(e) => {
                                                setFiles(e, 'a');
                                                inputRef.current.value = null;
                                            }}
                                            accept="image/*"
                                        />
                                    </label>
                                </div>
                                <p className="text-xs text-gray-500">PNG, JPG, JPEG, GIF</p>
                            </>

                        </div>
                    </div>
                </div>
                {files && files.length > 0 ?
                    <div className="">
                        <div className="mt-8 flow-root">
                            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        Preview
                                                    </th>
                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        Naam
                                                    </th>
                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                        Compressie
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Grootte
                                                    </th>

                                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                        <span className="sr-only">Verwijder</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {files.map((file, index) => (
                                                    <tr key={file.name}>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            <div className="flex items-center">
                                                                <div className="flex-shrink-0 h-16 w-auto mt-1 mb-1">
                                                                    <img src={URL.createObjectURL(file)} alt="" className="h-16 w-auto object-cover rounded-sm" />  
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            {file.name}
                                                        </td>
                                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                            <select className="bg-white rounded-md border border-gray-300 shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm w-3/4"
                                                                onChange={(e) => { compressFile(index, e.target.value) }}
                                                            >
                                                                {!file.name.includes("compressed") ?
                                                                <>
                                                                <option value="0">Geen compressie</option>
                                                                <option value="1">Optimaliseren (zelfde kwaliteit)</option>
                                                                <option value="2">Lage compressie (80%)</option>
                                                                <option value="3">Gemiddelde compressie (60%)</option>
                                                                <option value="4">Hoge compressie (40%)</option>
                                                                </>
                                                                : 
                                                                <option value="compressed" disabled selected>Reeds gecomprimeerd</option>
                                                }
                                                            </select>
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{(file.size / 1000000).toFixed(2)}MB</td>
                                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                            <a href="#" onClick={() => removeFile(file.name)} className="text-indigo-600 hover:text-indigo-900">
                                                                Verwijder<span className="sr-only">, {file.name}</span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex m-2">
                            <div className="w-1/2 text-gray-400">
                                {files.length > 0 && (
                                    <ul>
                                        <li>Bestandstypes: {fileTypes.join(', ')}</li>
                                        <li>Totale grootte: {totalSize}</li>
                                    </ul>
                                )}
                            </div>
                            <div className="w-1/2 text-right">
                                <button
                                    onClick={() => clearAllFiles()}
                                    type="button"
                                    className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                >
                                    Verwijder alle
                                </button>
                                <button
                                    onClick={handleSubmit}
                                    type="button"
                                    className="ml-2 inline-flex items-center rounded-md border border-transparent bg-lime-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2"
                                >
                                    Upload alle
                                </button>

                            </div>


                        </div>
                    </div>
                    : <></>}
            </div>




            {
                files ?
                    <>


                    </>
                    : <></>
            }
        </>
    )
}