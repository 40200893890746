import { Link } from 'react-router-dom'

export default function inBuild() {
    return (
        <div className="flex-shrink-0 py-auto py-64 p-5">
            <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">PAGINA VERBORGEN</p>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Deze pagina kan niet worden geladen.</h1>
            <p className="mt-2 text-base text-gray-500">Sorry, de pagina waarnaar je op zoek bent is op dit moment verborgen.</p>
            <div className="mt-6">
              <Link to={'/'} className="text-base font-medium text-indigo-600 hover:text-indigo-500">
                Dashboard<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
        </div>
    )
}