import { useState, useEffect, useRef } from 'react'
import  { useParams, Outlet, useNavigate } from 'react-router-dom'
import IframeResizer from 'iframe-resizer-react'
import { toast } from 'react-toastify'
import { PencilIcon, XCircleIcon } from '@heroicons/react/outline'
import { ThreeDots } from 'react-loader-spinner'

//import general components
import Spacer from "../../components/default/spacer"
import Seperator from "../../components/default/seperator"
import Breadcrumb from "../../components/default/breadcrumb"
import Title from "../../components/default/title"

//import services
import InteractService from "../../../services/interact.service"

export default function InteractEdit({content}) {
    let { id } = useParams();
    const ref = useRef();
    let navigate = useNavigate();
    const [loader, setLoader] = useState(true);

    const [interact, setInteract] = useState({
        "title": "Loading...", 
      });
    const [hotspots, setHotspots] = useState([]);
    const [messageData, setMessageData] = useState()

    const onMessage = data => {
        setMessageData(data.message)
    }

    const [initLine, setInitLine] = useState();
    const [scndInitLine, setScndInitLine] = useState();

    useEffect(() => {
        InteractService.get(id)
        .then((response) => {
            setInteract(response.data)
            setInitLine('img:' + process.env.REACT_APP_BACKEND_URL.slice(0, -1) + response.data.image);
            InteractService.getHotspots(id)
            .then((response) => {
                const hotspots = []
                for (var i=0; i < response.data.length; i++) { 
                    hotspots.push([response.data[i].uuid, response.data[i].location_x, response.data[i].location_y, response.data[i].title, response.data[i].color, response.data[i].size, response.data[i].type, response.data[i].content])
                }
                setHotspots(hotspots)
                setScndInitLine('init:' + btoa(JSON.stringify(hotspots)))
            })
            .catch((error) => {
                toast.error(error.message)
            })
        })
        .catch((error) => {
            toast.error(error.message)
        })
    }, [])

    useEffect(() => {
        if (initLine && scndInitLine) {
            setTimeout(() => {
                setLoader(false)
                ref.current.sendMessage("takeoff:" + btoa(JSON.stringify([initLine, scndInitLine])), '*')
            }, 500);

        }
    }, [initLine, scndInitLine])

    useEffect(() => {
        if (messageData) {
            if (messageData.includes("edit")) {
                var hotspot = messageData.slice(5)
                editHotspot(hotspot);
            } else if (messageData.includes("remv")) {
                var hotspot = messageData.slice(5)
                removeHotspot(hotspot);
            } else if (messageData.includes("save")) {
                var all = messageData.slice(5)
                var elements = atob(all).split(",")
                var allhotspots = []
                for (var i=0; i < elements.length; i++) { 
                    if (i % 3 == 0) {
                        allhotspots.push([elements[i], elements[i+1], elements[i+2]])
                    }
                }
                InteractService.updateLocations(id, allhotspots)
                .then((response) => {
                    toast.success(response.data)
                })
                .catch((error) => {
                    toast.error(error.message)
                })
            }
        }
    }, [messageData])

    function addHotspot() {
        const randomX = 5;
        const randomY = 5;
        const title = "Naamloos"
        const color = "#FFFFFF"
        const size = 15
        InteractService.createHotspot(id, title, color, randomX, randomY, size)
        .then((response) => {
            const newarr = [...hotspots, [response.data, randomX, randomY, title, color, size]]
            setHotspots(newarr)
            ref.current.sendMessage('init:' + btoa(JSON.stringify(newarr)), '*');
            toast.success("Hotspot aangemaakt (linksboven)!")
        })
        .catch((error) => {
            toast.error(error.message)
        })
    }

    function save() {
        ref.current.sendMessage('getall', '*');
    }

    function editHotspot(id) {
        ref.current.sendMessage('getall', '*');
        return navigate("hotspot/" + id + "/");
    }

    function removeHotspot(id) {
        InteractService.deleteHotspot(id)
        .then((response) => {
            toast.success(response.data)
            const newarr = hotspots.filter(hotspot => hotspot[0] !== id)
            setHotspots(newarr)
            ref.current.sendMessage('init:' + btoa(JSON.stringify(newarr)), '*');
        })
        .catch((error) => {
            toast.error(error.message)
        })
    }

    function copyToClipboard(id) {
        const el = document.createElement('textarea');
        el.value = process.env.REACT_APP_FRONTEND_URL + "hotspot/view.html?id=" + id;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toast.success("Link gekopieerd naar klembord!")
    }

    return (
    <div className="flex flex-wrap items-stretch h-screen p-5">
        <div className="lg:w-9/12 sm:w-full mr-5">
            <Title text="Interact" sidetext={interact.title} />
            {loader ?
            <div className="flex justify-center items-center h-96">
               <ThreeDots 
                height="80" 
                width="80" 
                radius="9"
                color={"rgb(226 232 240);"}
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
                />
            </div>
            :
            null }
            <IframeResizer
                forwardRef={ref}
                onMessage={onMessage}
                src="/hotspot/create.html"
                style={{ width: '1px', minWidth: '100%', marginTop: '15px'}}
            />
        </div>
        <div className="lg:w-3/12 sm:w-full flex-1 overflow-x-scroll ml-5">
            {hotspots.length > 1 
            ? <Title text="Hotspots" />
            : <Title text="Hotspot" />
            }
            <div className="overflow-y-scroll">
                <ul role="list" className="grid grid-cols-1 gap-3 mt-5">
                    {hotspots.map((hotspot) => (
                        <li key={hotspot[0]} className="col-span-1 bg-white rounded-lg shadow-lg divide-y divide-gray-200">
                        <div className="w-full flex items-center justify-between p-6 space-x-6">
                            <div className="flex-1 truncate">
                            <div className="flex items-center space-x-3">
                                <h3 className="text-gray-900 text-sm font-medium truncate">{hotspot[3]}</h3>
                            </div>
                            <p className="mt-1 text-gray-400 text-xs truncate">ID: {hotspot[0]}</p>
                            </div>
                        </div>
                        <div>
                            <div className="-mt-px flex divide-x divide-gray-200">
                            <div className="w-0 flex-1 flex">
                                <a
                                onClick={() => editHotspot(hotspot[0])}
                                className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                >
                                <PencilIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                                <span className="ml-3">Wijzigen</span>
                                </a>
                            </div>
                            <div className="-ml-px w-0 flex-1 flex">
                                <a
                                onClick={() => removeHotspot(hotspot[0])}
                                className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                                >
                                <XCircleIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                                <span className="ml-3">Verwijderen</span>
                                </a>
                            </div>
                            </div>
                        </div>
                        </li>
                    ))}
                    </ul>
                </div>
            <div className="flex py-5">
                <button 
                    type="button"
                    className="w-1/2 ml-3 inline-flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={e => addHotspot()}>
                        Hotspot toevoegen
                </button>
                <br />
                <button 
                    type="button"
                    className="w-1/2 ml-3 inline-flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={e => save()}>
                        Locaties opslaan
                </button>
            </div>
            <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                    <p className="text-sm"><span className="font-bold">Uw interact-URL:</span> <span className="underline italic cursor-pointer" onClick={() => copyToClipboard(interact.uuid)}>{process.env.REACT_APP_FRONTEND_URL}hotspot/view.html?id={interact.uuid}</span></p>
                </div>
            </div>
        </div>
        <Outlet />
    </div>
    )
}