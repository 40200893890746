import { Link } from 'react-router-dom'
import {
    ChevronDoubleRightIcon
} from '@heroicons/react/outline'


export default function Title({ text, sidetext, sidelink }) {
    return (
        <>
            <h1 className="text-2xl font-bold text-gray-800">{text}
            { sidetext ? 
            <span className="text-indigo-600 text-xs pl-4">
                {sidelink ?
                    <Link to={sidelink}>
                        <a>{sidetext}<span aria-hidden="true"> &rarr;</span></a>
                    </Link>
                :
                    <p>{sidetext}</p>
                }
            </span>
            : 
            <></>
            }
            </h1>
        </>
    )
}