import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify';

import AssetService from '../../../services/asset.service'
import interactService from '../../../services/interact.service';

const stats = [
  { name: 'Total Subscribers', stat: '71,897', previousStat: '70,946', change: '12%', changeType: 'increase' },
  { name: 'Avg. Open Rate', stat: '58.16%', previousStat: '56.14%', change: '2.02%', changeType: 'increase' },
  { name: 'Avg. Click Rate', stat: '24.57%', previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Stats() {
    const [assetInfo, setAssetInfo] = useState();
    const [interactInfo, setInteractInfo] =  useState();
    const [hotspotInfo, setHotspotInfo] = useState();


    useEffect(() =>{
        AssetService.getInfo()
        .then((response) => { 
            setAssetInfo(response.data)
        })
        .catch(() => {
            toast("Het is niet gelukt om jouw bestanden op te halen. Probeer het later opnieuw.");
        })
        interactService.getInfo()
        .then((response) => { 
            setInteractInfo(response.data)
        })
        .catch(() => {
            toast("Het is niet gelukt om jouw interacts op te halen. Probeer het later opnieuw.");
        })
        interactService.getHotspotsInfo()
        .then((response) => { 
            setHotspotInfo(response.data)
        })
        .catch(() => {
            toast("Het is niet gelukt om jouw hotspots op te halen. Probeer het later opnieuw.");
        })
      }, [])

    return (
        <>
            <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
                <div className="px-4 py-5 sm:p-6">
                    <dt className="text-base font-normal text-gray-900">Media-aantal</dt>
                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                        {assetInfo}
                        <span className="ml-2 text-sm font-medium text-gray-500">van de 100.</span>
                    </div>
                    </dd>
                </div>
                <div className="px-4 py-5 sm:p-6">
                    <dt className="text-base font-normal text-gray-900">Interact-aantal</dt>
                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                        {interactInfo}
                        <span className="ml-2 text-sm font-medium text-gray-500">van de 100.</span>
                    </div>
                    </dd>
                </div>
                <div className="px-4 py-5 sm:p-6">
                    <dt className="text-base font-normal text-gray-900">Hotspot-aantal</dt>
                    <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                    <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                        {hotspotInfo}
                        <span className="ml-2 text-sm font-medium text-gray-500">van de 100.</span>
                    </div>
                    </dd>
                </div>
            </dl>
        </>
    )
}