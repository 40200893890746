import { useState, useEffect } from 'react'
import { useFileUpload } from 'use-file-upload';
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useRecoilState } from 'recoil'
import Compressor from 'compressorjs';

// default components
import Spacer from '../default/spacer'

//services
import UploadService from '../../../services/upload.service'
import InteractService from '../../../services/interact.service'

//states
import { updateState } from '../../../data/update.atom'

export default function CreateInteract() {
    const navigate = useNavigate()
    const [update, setUpdate] = useRecoilState(updateState)
    const [title, setTitle] = useState("")
    const [removed, setRemoved] = useState()

    //image upload 
    const [file, selectFile] = useFileUpload()
    const [compressedFile, setCompressedFile] = useState(null);

    //compression vars
    const [compressed, setCompressed] = useState(false)
    const [compressGrade, setCompressGrade] = useState(0)
    const [compression] = useState([0, NaN, 0.8, 0.6, 0.4]);

    useEffect(() => {
        setRemoved(false)
    }, [file])

    function removeFile(e) {
        setRemoved(true)
        setCompressedFile(null)
    }

    function uploadFile(e) {
        e.preventDefault();

        if (compressedFile) {
            var image = compressedFile
        } else {
            var image = file.file
        }

        UploadService.image(image)
            .then((response) => {
                InteractService.createInteract(title, response.data)
                    .then((response) => {
                        setUpdate(update + 1)
                        navigate('/interact/edit/' + response.data + '/')
                    })
                    .catch((error) => {
                        toast(error.message)
                    })
            })
            .catch((error) => {
                toast(error.message)
            })
    }

    useEffect(() => {
        if (compressGrade > 0 && !compressed && compressGrade != "compressed" && file.file) {
            setCompressed(true)
            const compressionGrade = compression[compressGrade]

            new Compressor(file.file, {
                quality: compressionGrade,
                success(result) {
                    const newfile = new File([result], file.file.name, {
                        type: file.file.type,
                        lastModified: Date.now(),
                    });

                    setCompressedFile(newfile);

                    toast.success('Afbeelding gecomprimeerd.');
                },
                error(err) {
                    toast.error(err.message);
                },
            });
        }
    }, [compressGrade])

    return (
        <>
            <div className="md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Interact aanmaken</h2>
                </div>
            </div>
            <Spacer height={20} />
            <div className="grid grid-cols-2">
                <div className="m-5">
                    <div className="mt-1">
                        <input
                            type="text"
                            name="title"
                            id="title"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Interact titel"
                            onChange={e => setTitle(e.target.value)}
                            autocomplete="off"
                        />
                    </div>
                    <div className="mt-1">
                        <select
                            className="bg-white rounded-md border border-gray-300 shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm w-full"
                            onChange={e => setCompressGrade(e.target.value)}
                        >
                            {!compressed && file
                                ? <>
                                    <option value="0">Geen compressie</option>
                                    <option value="1">Optimaliseren (zelfde kwaliteit)</option>
                                    <option value="2">Lage compressie (80%)</option>
                                    <option value="3">Gemiddelde compressie (60%)</option>
                                    <option value="4">Hoge compressie (40%)</option>
                                </>
                                : compressed && file ?
                                    <>
                                        <option value="compressed" disabled selected>Reeds gecomprimeerd.</option>
                                    </>
                                    :
                                    <>
                                        <option value="compressed" disabled selected>Geen bestand geselecteerd.</option>
                                    </>}
                        </select>
                    </div>
                    <div className="mt-4 flex">
                        {file ?
                            <button
                                type="button"
                                className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={e => removeFile()}
                            >
                                Opnieuw beginnen
                            </button>
                            : <></>
                        }
                        {title && file ?
                            <button
                                type="button"
                                className="text-white ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={e => uploadFile(e)}
                            >
                                Interact aanmaken
                            </button>
                            : <></>
                        }
                    </div>
                </div>
                <div className=" m-5">
                    <div className="mt-1 sm:mt-0">
                        <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                            <div className="space-y-1 text-center">
                                {(!file && !compressedFile) || removed ?
                                    <>
                                        <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                strokeWidth={2}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        <div className="flex text-sm text-gray-600">
                                            <label
                                                htmlFor="file-upload"
                                                className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                            >
                                                <button onClick={() => { selectFile({ accept: 'image/*' }) }}><span>Upload een bestand</span></button>
                                            </label>
                                        </div>
                                        <p className="text-xs text-gray-500">Maximum 1 bestand</p>
                                        <p className="text-xs text-gray-500">PNG, JPG, JPEG tot 16MB</p>
                                    </>
                                    : file && !compressedFile ?
                                        <>
                                            <img src={file.source} className="rounded-md mb-4" />
                                            <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{file.name}</p>
                                            <p className="block text-sm font-medium text-gray-500 pointer-events-none">{(file.size * 0.000001).toFixed(2)}MB</p>
                                        </>
                                        : compressedFile ?
                                            <>
                                                <img src={
                                                    URL.createObjectURL(compressedFile)
                                                } className="rounded-md mt-4 mb-4" />
                                                <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{compressedFile.name}</p>
                                                <p className="block text-sm font-medium text-gray-500 pointer-events-none">{(compressedFile.size * 0.000001).toFixed(2)}MB</p>
                                            </>
                                            : <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}