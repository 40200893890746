import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useEffect } from 'react'
import { useRecoilState } from 'recoil';
import { toast } from 'react-toastify';

// default views
import Menu  from './views/menu'
import NotFound  from './views/notfound'
import Login  from './views/auth/login'
import InBuild from './views/components/default/inbuild'

// page views
import Dashboard from './views/dashboard'
import Media from './views/media'
import Settings from './views/settings'
import Interact from './views/interact'
import InteractEdit from './views/components/interact/edit'
import HotspotEdit from './views/components/hotspot/edit'

// services
import InitService from './services/init.service';
import AssetService from './services/asset.service'
import InteractService from './services/interact.service';

// datastores
import { loggedInState } from './data/loggedIn.atom'
import { userState } from './data/user.atom'
import { assetState } from './data/asset.atom'
import { interactState } from './data/interact.atom'
import { updateState } from './data/update.atom'

function App() {
  const [loggedIn, setLoggedIn] = useRecoilState(loggedInState);
  const [user, setUser] = useRecoilState(userState)
  
  useEffect(() => {
    InitService.initMe()
    .then((response) => { 
      setUser(response.data)
      setLoggedIn(true)
    })
    .catch(() => {
      setLoggedIn(false)
     }
    )
  
  }, [])

  const [assets, setAssets] = useRecoilState(assetState)
  const [interacts, setInteracts] = useRecoilState(interactState)
  const [update, setUpdate] = useRecoilState(updateState)

  useEffect(() =>{
    if (loggedIn) {
        AssetService.getAll()
        .then((response) => { 
            setAssets(response.data)
        })
        .catch(() => {
            toast("Het is niet gelukt om jouw bestanden op te halen. Probeer het later opnieuw.");
        }
        )
        InteractService.list()
        .then((response) => {
            setInteracts(response.data)
        })
        .catch((error) => {
          toast("Het is niet gelukt om jouw interacts op te halen. Probeer het later opnieuw.");
        })
      }
  }, [update, loggedIn])

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/view/:id" element={<p>ok</p>} />

          { loggedIn == false 
          ? <Route path="*" element={<Login />} />
          :
          <>
          <Route path='*' element={<NotFound />} />
          <Route path="/" element={<Navigate to="/dashboard/" />} />

          <Route path="/dashboard/" element={<Menu content={<Dashboard />} />} />

          <Route path="/interact/" element={<Menu content={<Interact />} />} /> 
          <Route path="/interact/edit/:id/" element={<Menu content={<InteractEdit />} />}/>
          <Route path="/interact/edit/:id/hotspot/:hotid/" element={<Menu content={<HotspotEdit />} /> } />


      {/*     <Route path="/interact/" element={<Menu content={<InBuild />} />} /> 
          <Route path="/interact/edit/:id/" element={<Menu content={<InBuild />} />} /> */}


          <Route path="/media/" element={<Menu content={<Media />} />} />
          <Route path="/playlist/" element={<Menu content={<InBuild />} />} />
          <Route path="/playlist/edit/:id/" element={<Menu content={<InBuild />} />} />



          {/* <Route path="/instellingen/" element={<Menu content={<Settings />} />} /> */}
          <Route path="/instellingen/" element={<Menu content={<InBuild />} />} />
          </>
          }
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  )
}

export default App;
