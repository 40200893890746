//generic components
import Spacer from "./components/default/spacer"
import Seperator from "./components/default/seperator"

// view specific components
import MediaList from "./components/dashboard/media"
import CreateMedia from "./components/media/create"

export default function Media() {
    return (
        <div className="p-5">
            <CreateMedia />
            <Spacer height={50} />
            <Seperator text="Bibliotheek" />
            <Spacer height={25} />
            <MediaList limited={false} />
        </div>
    )
}