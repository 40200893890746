import api from './api/api';

class InteractService {
    createInteract(title, img) {
        return api.post("/interact/create/", {
            title: title,
            img: img
        })
    }

    get(id) {
        return api.get("/interact/get/" + id + "/")
    }

    list() {
        return api.get("/interact/list/")
    }

    getInfo() {
        return api.get("/interact/info/");
    }

    getHotspotsInfo() {
        return api.get("/interact/hotspot/info/");
    }

    getHotspots(id) {
        return api.get("/interact/hotspot/list/" + id + "/")
    }

    createHotspot(id, title, color, x, y, size) {
        return api.post("/interact/hotspot/create/" + id + "/", {
            title: title,
            color: color,
            size: size, 
            location_x: x,
            location_y: y
        })
    }

    updateLocations(id, locations) {
        return api.post("/interact/hotspot/list/updateloc/" + id + "/", {
            hotspots: locations
        })
    }

    deleteHotspot(id) {
        return api.delete("/interact/hotspot/delete/" + id + "/")
    }
}

export default new InteractService();