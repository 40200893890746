import { Link } from 'react-router-dom'

// default components
import Title from "./components/default/title"
import Spacer from "./components/default/spacer"

// page specific components
import InteractList from "./components/dashboard/interactlist"
import MediaList from "./components/dashboard/media"
import Stats from "./components/dashboard/stats"


export default function Dashboard() {
    return (
        <div className="p-5">  
            <div className="md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Dashboard</h2>
                </div>
                <div className="mt-4 flex md:mt-0 md:ml-4">
                    <Link to="/media/">
                        <button
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                        Media uploaden
                        </button>
                    </Link>
                    <Link to="/interact/">
                        <button
                        type="button"
                        className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                        Interact aanmaken
                        </button>
                    </Link>
                </div>
            </div>
            <Spacer height={15} />
            <Stats />
            <Spacer height={50} />
            <Title text="Jouw interacts" sidetext="Alle interacts bekijken" sidelink="/interact/" />
            <Spacer height={15} />
            <InteractList />
            <Spacer height={50} />
            <Title text="Jouw media" sidetext="Alle media bekijken" sidelink="/media/" />
            <Spacer height={15} />
            <MediaList limited={true} />
        </div>
    )
}