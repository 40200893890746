import api from './api/api';

class UploadService {
    image(file) {
        const formData = new FormData();
        formData.append("file", file);
        return api.post("/assets/upload/", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    multipleImage(files) {
        return api.post("/assets/upload/multiple/", files, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }
}

export default new UploadService();