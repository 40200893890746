//import general components
import Spacer from "./components/default/spacer"
import Seperator from "./components/default/seperator"

//import page specific components
import CreateInteract from "./components/interact/create"
import Interacts from "./components/dashboard/interacts"

export default function Interact() {
    return (
        <div className="p-5">
            <CreateInteract />
            <Spacer height={50} />
            <Seperator text="Bibliotheek" />
            <Spacer height={15} />
            <Interacts />
        </div>
    )
}