import api from "./api/api";
import TokenService from "./api/token";

class AuthService {
  login(username, password) {
    return api
      .post("/auth/login/", {
        username,
        password
      })
      .then(response => {
        if (response.data.access_token) {
          TokenService.setUser(response.data);
        }
        return response;
      })
  }

  logout() {
    TokenService.removeUser();
    return "ok"
  }

  register(username, email, password) {
    return api.post("/auth/signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return TokenService.getUser();
  }
}

export default new AuthService();