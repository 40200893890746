import api from './api/api';

class AssetService {
    getAll() {
        return api.get("/assets/list/");
    }

    getInfo() {
        return api.get("/assets/info/");
    }

    update(id, title) {
        return api.post("/assets/update/", {
            data: {
                id: id,
                title: title
            }
        })
    }

    remove(id) {
        return api.post("/assets/remove/", {
            data: id
        })
    }
}

export default new AssetService();