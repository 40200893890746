import { useRecoilState } from 'recoil'
import { toast } from 'react-toastify'
import { PencilIcon } from '@heroicons/react/outline'
import { useState } from 'react'

// services
import AssetService from '../../../services/asset.service'

// data stores
import { assetState } from "../../../data/asset.atom"
import { updateState } from "../../../data/update.atom"

export default function MediaList({ limited }) {
  const [assets, setAssets] = useRecoilState(assetState)
  const [update, setUpdate] = useRecoilState(updateState)


  const [editting, setEditting] = useState(null)
  const [newTitle, setNewTitle] = useState("")

  function handleEdit(id) {
    setEditting(id)
  }

  function changeTitle(value, id) {
    setNewTitle(value)
  }

  function updateTitle(id) {
    AssetService.update(id, newTitle)
      .then((response) => {
        toast.success(response.data)
        setUpdate(update + 1)
        setEditting(null)
        setNewTitle("")
      })
      .catch((error) => {
        toast.error(error.message)
      })
  }

  function remove(e, id) {
    const qresult = window.prompt("Weet je zeker dat je dit bestand wilt verwijderen? Zorg dat de afbeelding NIET gebruikt wordt in een interact. (type 'ja' om te bevestigen)")
    if (qresult !== "ja") {
      return
    } else {
      AssetService.remove(id)
        .then((response) => {
          toast.success(response.data.message)
          setAssets(assets.filter(asset => asset.id !== id))
        })
        .catch((error) => {
          toast.error(error.message)
        })
    }
  }

  return (
    <>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-100">
                  <tr>
                    {!limited ?
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                      >
                        Playlist
                      </th>
                      : null}
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Bestand
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Informatie
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Link
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">verwijderen</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {limited
                    ? assets.slice(0, 5).map((asset) => (
                      <tr key={asset.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-auto w-56">
                              <img className="rounded-md shadow-md" src={process.env.REACT_APP_BACKEND_URL + "/assets/" + asset.name} alt="" />
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{asset.title ? asset.title : asset.o_name}</div>
                          <br />
                          {asset.title ? <div className="text-sm text-gray-500">Originele naam: {asset.o_name}</div> : null}
                          <div className="text-sm text-gray-500">Interne naam: {asset.name}</div>
                          <div className="text-sm text-gray-500">Aangemaakt op: {asset.created_on}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap font-medium text-sm text-indigo-600 hover:text-indigo-900"><a href="#" onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_BACKEND_URL + "assets/" + asset.name)}>{process.env.REACT_APP_BACKEND_URL + "assets/" + asset.name}</a></td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <a href="#" className="text-indigo-600 hover:text-indigo-900" onClick={(e) => remove(e, asset.id)}>
                            Verwijderen
                          </a>
                        </td>
                      </tr>
                    ))
                    : assets.map((asset) => (
                      <tr key={asset.id}>
                        <td>
                          <div className="text-sm text-gray-900">
                            <div className="flex items-center">
                              <input
                                type="checkbox"
                                name="playlist"
                                id="playlist"
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded 
                                cursor-pointer mx-auto"

                              />
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-auto w-56">
                              <img className="rounded-md shadow-md" src={process.env.REACT_APP_BACKEND_URL + "assets/" + asset.name} alt="" />
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {editting == asset.id ?
                            <input
                              type="text"
                              name="title"
                              id="title"
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="Titel"
                              defaultValue={asset.title ? asset.title : asset.o_name}
                              onChange={(e) => changeTitle(e.target.value, asset.id)}
                              onBlur={(e) => { updateTitle(asset.id) }}
                              onKeyDown={(e) => { if (e.key === 'Enter') { updateTitle(asset.id) } }}
                            />
                            : <div className="flex items-center space-x-2">
                              <div className="text-sm text-gray-900">
                                {asset.title ? asset.title : asset.o_name}
                              </div>
                              <PencilIcon className="w-4 h-4 text-gray-500 hover:text-gray-700 cursor-pointer" onClick={() => handleEdit(asset.id)} />
                            </div>}
                          <br />
                          {asset.title ? <div className="text-sm text-gray-500">Originele naam: {asset.o_name}</div> : null}
                          <div className="text-sm text-gray-500">Interne naam: {asset.name}</div>
                          <div className="text-sm text-gray-500">Aangemaakt op: {asset.created_on}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap font-medium text-sm text-indigo-600 hover:text-indigo-900"><a href="#" onClick={() => navigator.clipboard.writeText(process.env.REACT_APP_BACKEND_URL + "assets/" + asset.name)}>{process.env.REACT_APP_BACKEND_URL + "assets/" + asset.name}</a></td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <a href="#" className="text-indigo-600 hover:text-indigo-900" onClick={(e) => remove(e, asset.id)}>
                            Verwijderen
                          </a>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}