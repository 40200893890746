import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ChromePicker } from 'react-color';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate } from 'react-router-dom'
import { useFileUpload } from 'use-file-upload';
import Compressor from 'compressorjs';

// default components
import Title from '../default/title'
import Spacer from '../default/spacer'

//services
import HotspotService from '../../../services/hotspot.service'
import UploadService from '../../../services/upload.service'

export default function HotspotEdit() {
    const params = useParams();
    const navigate = useNavigate();


    const [editorState, setEditorState] = useState();
    const [uri, setUri] = useState();

    const [productTitle, setProductTitle] = useState();
    const [productDescription, setProductDescription] = useState();
    const [productImage, setProductImage] = useState();
    const [productUri, setProductUri] = useState();

    const [hotspot, setHotspot] = useState();
    const [title, setTitle] = useState();
    const [color, setColor] = useState({ hex: null });
    const [size, setSize] = useState(20);
    const [icon, setIcon] = useState();
    const [type, setType] = useState("");

    // upload image
    const [file, selectFile] = useFileUpload()
    const [compressedFile, setCompressedFile] = useState(null);
    const [removed, setRemoved] = useState()

    //compression vars
    const [compressed, setCompressed] = useState(false)
    const [compressGrade, setCompressGrade] = useState(0)
    const [compression] = useState([0, NaN, 0.8, 0.6, 0.4]);

    useEffect(() => {
        setRemoved(false)
    }, [file])

    function removeFile(e) {
        setRemoved(true)
        setCompressedFile(null)
    }


    //temporary dev notification
    useEffect(() => {
        if (icon) {
            toast.info("Het icoon is nog niet zichtbaar in de hotspot. Deze functie is nog in ontwikkeling.", {
                autoClose: 8000
            })
            toast.warning("Indien je het icoon toch opslaat wordt deze weergegeven in de hotspot eens de functionaliteit beschikbaar is.", {
                autoClose: 12000
            })
        }
    }, [icon])


    // image compressor
    useEffect(() => {
        if (compressGrade > 0 && !compressed && compressGrade != "compressed" && file.file) {
            setCompressed(true)
            const compressionGrade = compression[compressGrade]

            new Compressor(file.file, {
                quality: compressionGrade,
                success(result) {
                    const newfile = new File([result], "compressed_" + file.file.name, {
                        type: file.file.type,
                        lastModified: Date.now(),
                    });

                    setCompressedFile(newfile);

                    toast.success('Afbeelding gecomprimeerd.');
                },
                error(err) {
                    toast.error(err.message);
                },
            });
        }
    }, [compressGrade])


    useEffect(() => {
        HotspotService.get(params.hotid)
            .then((response) => {
                console.log(response)
                setHotspot(response.data)
                setTitle(response.data.title)
                if (response.data.color) {
                    console.log(response.data.color)
                    if (response.data.color.includes("#")) {
                        setColor({ hex: response.data.color })
                    }
                }
                setSize(response.data.size)
                setType(response.data.dtype)
                setIcon(response.data.icon)
                if (response.data.dtype == "uri") {
                    setUri(response.data.dcontent)
                }
                if (response.data.dtype == "dynamic") {
                    const content = response.data.dcontent
                    const contentBlock = htmlToDraft(content)
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                    const editorState = EditorState.createWithContent(contentState)
                    setEditorState(editorState)
                }
                if (response.data.dtype == "product") {
                    const product = JSON.parse(response.data.dcontent)
                    setProductTitle(product.title)
                    setProductDescription(product.description)
                    setProductImage(product.image)
                    setProductUri(product.uri)
                }
            })
            .catch((error) => {
                toast(error.message)
            })
    }, [])

    async function saveHotspot() {
        let uploadedProductImage = null;

        if (file || compressedFile) {
            if (type == "product") {
                if (compressedFile) {
                    var image = compressedFile
                } else {
                    var image = file.file
                }

                try {
                    const response = await UploadService.image(image)
                    if (response.status == 200) {
                        uploadedProductImage = response.data
                        setProductImage(response.data)
                    }
                }
                catch (error) {
                    toast.error(error.message)
                }
            }
        } else {
            if (type == "product") {
                uploadedProductImage = productImage
            }
        }

        const shotspot = [
            hotspot.uuid,
            title,
            color.hex,
            size,
            icon,
            type,
        ]
        if (type == "uri") {
            shotspot.push(uri)
        }
        if (type == "dynamic") {
            shotspot.push(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        }
        if (type == "product") {
            const product = {
                "title": productTitle,
                "description": productDescription,
                "image": uploadedProductImage,
                "uri": productUri,
            }
            shotspot.push(JSON.stringify(product))
        }
        if (type == null) {
            toast.warning("Je hebt geen type geselecteerd, je hotspot is leeg.")
            shotspot.push("")
        }
        const data = {
            "uuid": shotspot[0],
            "title": shotspot[1],
            "color": shotspot[2] ? shotspot[2] : "#000000",
            "size": shotspot[3],
            "icon": shotspot[4],
            "type": shotspot[5],
            "content": shotspot[6],
        }
        HotspotService.update(params.hotid, data)
            .then((response) => {
                toast.success("Hotspot succesvol opgeslagen.")
                navigate(-1)
            }
            )
            .catch((error) => {
                toast(error.message)
            }
            )
    }

    return (
        <div className="flex flex-wrap items-stretch h-screen p-5">
            <div className="w-full mr-5">
                <div className="md:flex md:items-center md:justify-between">
                    <div className="min-w-0 flex-1">
                        {hotspot
                            ? <Title text="Hotspot wijzigen" sidetext={title} />
                            : <Title text="Hotspot wijzigen" sidetext="Loading..." />}
                    </div>
                    <div className="mt-4 flex md:mt-0 md:ml-4">
                        <button
                            onClick={() => navigate(-1)}
                            type="button"
                            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            Annuleren
                        </button>
                        <button
                            type="button"
                            className="ml-3 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => saveHotspot()}
                        >
                            Opslaan
                        </button>
                    </div>
                </div>

                <Spacer height={40} />
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Titel
                    </label>
                    <div className="mt-1">
                        <input
                            type="email"
                            name="email"
                            id="email"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="Interact-naam"
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                        />
                    </div>
                </div>
                <Spacer height={20} />
                <div className="flex">
                    <div className="w-full lg:w-1/3">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Kleur
                        </label>
                        <Spacer height={5} />
                        <ChromePicker
                            width={350}
                            color={color}
                            onChange={(color) => setColor(color)}

                        />
                    </div>
                    <div className="w-full lg:w-1/3 pr-10">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Grootte
                        </label>
                        <Spacer height={5} />
                        <div className="mt-1">
                            <input
                                type="number"
                                name="number"
                                id="number"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                min={10}
                                max={40}
                                value={size}
                                onChange={(event) => setSize(event.target.value)}
                            />
                        </div>
                        <Spacer height={15} />
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                            Icoon
                        </label>
                        <div className="mt-1">
                            {/* <IconPicker value={icon} onChange={(v) => setIcon(v)} /> */}
                            <select
                                value={icon}
                                onChange={(event) => setIcon(event.target.value)}
                                defaultValue="Geen icoon"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            >
                                <option value="">Geen icoon</option>
                                <option value="fa fa-plus">Plus</option>
                            </select>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3">
                        <p className="block text-sm font-medium text-gray-700">
                            Voorbeeld
                        </p>
                        <Spacer height={5} />
                        <div className="shadow-lg rounded-full" style={color ? { backgroundColor: color.hex, width: size + "px", height: size + "px" } : { backgroundColor: "gray", width: size + "px", height: size + "px" }}>
                        </div>
                        {color.hex == "#fff" || color.hex == "#ffffff" || color.hex == "#ffff" || color.hex == "#fffff"
                            ?
                            <>
                                <Spacer height={15} />
                                <p
                                    className="text-sm text-gray-300"
                                >Notitie: het voorbeeld van een witte hotspot is niet goed zichtbaar.</p>
                            </>
                            : null
                        }
                        <Spacer height={5} />
                    </div>
                </div>
                <Spacer height={20} />
                <div>
                    <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                        Inhoudstype
                    </label>
                    <select
                        id="location"
                        name="location"
                        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        defaultValue="Selecteer..."
                        value={type}
                        onChange={(event) => setType(event.target.value)}
                    >

                        <option value="Selecteer...">Selecteer...</option>
                        <option value="dynamic">Dynamisch</option>
                        <option value="uri">URL</option>
                        <option value="product">Product</option>
                    </select>
                </div>
                <Spacer height={40} />
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Inhoud
                    </label>
                    <Spacer height={5} />
                    {type ?
                        type == "dynamic"
                            ?
                            <div className="bg-white">
                                <Editor
                                    editorState={editorState}
                                    onEditorStateChange={(editorState) => setEditorState(editorState)}
                                />
                            </div>
                            : type == "uri" ?
                                <div>
                                    <div className="mt-1">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            placeholder="https://www.example.com"
                                            value={uri}
                                            onChange={(event) => setUri(event.target.value)}
                                        />
                                    </div>
                                </div>
                                : type == "product" ?
                                    <div>
                                        <div className="mt-1 max-w-6xl">
                                            <input
                                                type="text"
                                                name="productTitle"
                                                id="productTitle"
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                placeholder="Product titel"
                                                value={productTitle}
                                                onChange={(event) => setProductTitle(event.target.value)}
                                            />

                                            <Spacer height={10} />
                                            <textarea
                                                type="text"
                                                name="productDescription"
                                                id="productDescription"
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                placeholder="Product beschrijving"
                                                value={productDescription}
                                                onChange={(event) => setProductDescription(event.target.value)}
                                                rows={2}
                                            />

                                            <Spacer height={10} />

                                            {productImage ?
                                                <div className="grid grid-cols-2">
                                                    <div>
                                                        <img
                                                            src={process.env.REACT_APP_BACKEND_URL.slice(0, -1) + productImage}
                                                            className="rounded-md mb-4 border-dashed border-2 border-gray-300"
                                                        />
                                                    </div>
                                                    <div className='ml-2'>
                                                        <button
                                                            type="button"
                                                            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                            onClick={e => setProductImage("") && setRemoved(true)}
                                                        >
                                                            Afbeelding wijzigen
                                                        </button>
                                                    </div>
                                                </div>
                                                :
                                                <div className="grid grid-cols-2">
                                                    <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                        <div className="space-y-1 text-center">
                                                            {(!file && !compressedFile) || removed ?
                                                                <>
                                                                    <svg
                                                                        className="mx-auto h-12 w-12 text-gray-400"
                                                                        stroke="currentColor"
                                                                        fill="none"
                                                                        viewBox="0 0 48 48"
                                                                        aria-hidden="true"
                                                                    >
                                                                        <path
                                                                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                            strokeWidth={2}
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        />
                                                                    </svg>
                                                                    <div className="flex text-sm text-gray-600">
                                                                        <label
                                                                            htmlFor="file-upload"
                                                                            className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                                        >
                                                                            <button onClick={() => { selectFile({ accept: 'image/*' }) }}><span>Upload een bestand</span></button>
                                                                        </label>
                                                                    </div>
                                                                    <p className="text-xs text-gray-500">Maximum 1 bestand</p>
                                                                    <p className="text-xs text-gray-500">PNG, JPG, JPEG tot 16MB</p>
                                                                </>
                                                                : file && !compressedFile ?
                                                                    <>
                                                                        <img src={file.source} className="rounded-md mb-4" />
                                                                        <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{file.name}</p>
                                                                        <p className="block text-sm font-medium text-gray-500 pointer-events-none">{(file.size * 0.000001).toFixed(2)}MB</p>
                                                                    </>
                                                                    : compressedFile ?
                                                                        <>
                                                                            <img src={
                                                                                URL.createObjectURL(compressedFile)
                                                                            } className="rounded-md mt-4 mb-4" />
                                                                            <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{compressedFile.name}</p>
                                                                            <p className="block text-sm font-medium text-gray-500 pointer-events-none">{(compressedFile.size * 0.000001).toFixed(2)}MB</p>
                                                                        </>
                                                                        : <></>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="ml-2">
                                                            <select
                                                                className="bg-white rounded-md border border-gray-300 shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm w-full"
                                                                onChange={e => setCompressGrade(e.target.value)}
                                                            >
                                                                {!compressed && file
                                                                    ? <>
                                                                        <option value="0">Geen compressie</option>
                                                                        <option value="1">Optimaliseren (zelfde kwaliteit)</option>
                                                                        <option value="2">Lage compressie (80%)</option>
                                                                        <option value="3">Gemiddelde compressie (60%)</option>
                                                                        <option value="4">Hoge compressie (40%)</option>
                                                                    </>
                                                                    : compressed && file ?
                                                                        <>
                                                                            <option value="compressed" disabled selected>Reeds gecomprimeerd.</option>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <option value="compressed" disabled selected>Geen bestand geselecteerd.</option>
                                                                        </>}
                                                            </select>
                                                        </div>
                                                        <div className="mt-1 ml-2">
                                                            {file ?
                                                                <button
                                                                    type="button"
                                                                    className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                                    onClick={e => removeFile()}
                                                                >
                                                                    Afbeelding verwijderen
                                                                </button>
                                                                : <></>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }


                                            <Spacer height={10} />

                                            <input
                                                type="text"
                                                name="productUri"
                                                id="productUri"
                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                placeholder="Product URL"
                                                value={productUri}
                                                onChange={(event) => setProductUri(event.target.value)}
                                            />
                                        </div>
                                    </div>



                                    :
                                    <>
                                        <p>Er is een fout opgetreden. Herlaad de pagina.</p>
                                    </>
                        :
                        <p>Geen inhoudstype geselecteerd</p>
                    }
                </div>
            </div>
        </div>
    )
}