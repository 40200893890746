import { useRecoilState } from 'recoil'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

// services
import AssetService from '../../../services/asset.service'

// data stores
import { interactState } from "../../../data/interact.atom"

export default function InteractList() {
    const [interacts, setInteracts] = useRecoilState(interactState)

    function remove(e, id) {
      AssetService.remove(id)
      .then((response) => {
        alert(response.data)
      })
      .catch((error) => {
        toast(error.message)
      })
    }

    return (
      <>
        <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-100">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Interact
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Informatie
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            Link
                          </th>
                          <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">verwijderen</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        { interacts.slice(0, 5).map((interacts) => (
                            <tr key={interacts.uuid}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                <Link to={"/interact/edit/" + interacts.uuid}>
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 h-auto w-56">
                                        <img className="rounded-md shadow-md" src={ process.env.REACT_APP_BACKEND_URL + interacts.image} alt="" />
                                        </div>
                                    </div>
                                </Link>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                <Link to={"/interact/edit/" + interacts.uuid}>
                                    <div className="text-sm text-gray-900">{interacts.title}</div>
                                </Link>
                                <br />
                                <div className="text-sm text-gray-500">Interne naam: {interacts.uuid}</div>
                                <div className="text-sm text-gray-500">Aangemaakt op: {interacts.created_on}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap font-medium text-sm text-indigo-600 hover:text-indigo-900">
                                 <a 
                                  href="#"
                                  onClick={() => {
                                    navigator.clipboard.writeText(process.env.REACT_APP_FRONTEND_URL + "hotspot/view.html?id=" + interacts.uuid)
                                    toast.success('Link gekopieerd naar klembord')
                                  }}
                                  >
                                    {process.env.REACT_APP_FRONTEND_URL + "hotspot/view.html?id=" + interacts.uuid}
                                  </a>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <a href="#" className="text-indigo-600 hover:text-indigo-900" onClick={(e) => remove(e, interacts.id)}>
                                    Verwijderen
                                </a>
                                </td>
                            </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
      </>
    )
  }