import api from './api/api';

class HotspotService {
    get(id) {
        return api.get("/hotspot/get/" + id + "/")
    }

    update(id, data) {
        return api.post("/hotspot/update/" + id + "/", data)
    }
}

export default new HotspotService();