import axios from "axios";
import TokenService from "./token";
import { toast } from 'react-toastify';

import AuthService from "../auth.service"

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token; 
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== "/auth/refresh/" && err.response) {
      if ((err.response.status === 401 || err.response.status === 422) && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const requestOptions = {
              method: 'POST',
              headers: 
              { 'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + TokenService.getLocalRefreshToken() }
          };
          const rs = await instance.post("/auth/refresh/", requestOptions)
          console.log(TokenService.getLocalRefreshToken())

          const { accessToken } = rs.data;
          TokenService.updateLocalAccessToken(accessToken);

          return instance(originalConfig);
        } catch (_error) {
          toast("We failed to authenticate you, please re-login.");
        }
      } else {
        toast(err.response.data);
     }
    } 

    return Promise.reject(err);
  }
);

export default instance;