import { useState } from 'react'
import { useRecoilState } from 'recoil'
import { toast } from 'react-toastify';

//local functions
import AuthService from "../../services/auth.service";

//local datastores
import { loggedInState } from '../../data/loggedIn.atom'

export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loggedIn, setLoggedIn] = useRecoilState(loggedInState);

    function login(e) {
        e.preventDefault();
        const auth = AuthService.login(username, password);
        auth
            .then(() => {
                setLoggedIn(true)
            })
            .catch(error => {
                toast("U heeft de verkeerde inloggegevens opgegeven. (" + error + ")")
            })
    }
    return (
        <>
            <div className="flex items-center gap-x-6 bg-indigo-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
                <p className="text-sm leading-6 text-white">
                    <a href="https://v2.mediacloud.optimize-it.be/admin/">
                        <strong className="font-semibold">MediaCloud Versie 1</strong>
                        <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                            <circle cx={1} cy={1} r={1} />
                        </svg>
                        Met V1 maak je interacts aan en deel je foto's
                        <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                            <circle cx={1} cy={1} r={1} />
                        </svg>
                        Met V2 deel je foto's en video's&nbsp;<span aria-hidden="true">&rarr;</span>
                    </a>
                </p>
                <div className="flex flex-1 justify-end">
                </div>
            </div>
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Log in op je<br />MediaCloud-account.</h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        of{' '}
                        <a href="https://optimize-it.be/" className="font-medium text-indigo-600 hover:text-indigo-500">
                            contacteer ons om je te registreren.
                        </a>
                    </p>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form className="space-y-6" action="#" method="POST">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    E-mailadres
                                </label>
                                <div className="mt-1">
                                    <input
                                        onChange={e => setUsername(e.target.value)}
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Paswoord
                                </label>
                                <div className="mt-1">
                                    <input
                                        onChange={e => setPassword(e.target.value)}
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className="flex items-center justify-between">
                                <div className="text-sm">
                                    <a href="https://optimize-it.be/" className="font-medium text-indigo-600 hover:text-indigo-500">
                                        Je wachtwoord vergeten?
                                    </a>
                                </div>
                            </div>

                            <div>
                                <button
                                    onClick={login}
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Inloggen
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}